<template>
    <transition name="preloader">
        <div id="preloader-screen" v-if="isVisible">
            <div class="preloader-wrapper">
                <img src="@/assets/logoanimation/border.svg" id="border" />
                <img src="@/assets/logoanimation/E.svg" id="logo-e" />
                <img src="@/assets/logoanimation/trust.svg" id="trust" />
                <img src="@/assets/logoanimation/medical.svg" id="medical" />
                <img src="@/assets/logoanimation/dedykowane.svg" id="dedykowane" />
            </div>
        </div>
    </transition>
    <PageHeader />
    <NavInfo v-if="$route.name != 'home'" />
    <main>
        <router-view v-if="!isVisible"></router-view>
    </main>
    <transition name="preloader">
        <Cookies v-if="cookiesStatus" @acceptCookies="setCookie('cookie', 'popupStatus', { days: 7, path: '/' })" />
    </transition>
    <PageFooter />
</template>

<script>
import NavInfo from "@/components/default/NavInfo.vue";
import PageHeader from "@/layouts/PageHeader.vue";
import PageFooter from "@/layouts/PageFooter.vue";
import Cookies from "@/components/default/Cookies.vue";

export default {
    components: {
        NavInfo,
        PageHeader,
        PageFooter,
        Cookies,
    },

    data() {
        return {
            isVisible: true,
            cookiesStatus: true,
        };
    },

    mounted() {
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener("resize", this.onResize);
        });
        document.querySelector("body").style.overflowY = "hidden";
        this.hideLoader();
        this.cookiePopup();
        
        document.dispatchEvent(new Event('render-event'));
    },

    updated() {
        const buttons = document.querySelectorAll(".btn-anim");
        const dots = document.querySelectorAll(".b");
        const lineRight = document.querySelectorAll(".workflow-filler-right");
        const lineLeft = document.querySelectorAll(".workflow-filler-left");



        const headers = document.querySelectorAll(".anim");
        const observerHeader = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    entry.target.childNodes.forEach((word) => {
                        word.style.animation = `textAnimation .7s ${word.dataset.delay} forwards`;
                    });
                } else {
                    entry.target.style.animation = "none";
                }
            });
        });
        headers.forEach((header) => {
            observerHeader.observe(header);
        });

        const observerButton = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0) {
                    entry.target.style.animation = `btnAnimation .7s ${entry.target.dataset.delay} forwards`;
                } else {
                    entry.target.style.animation = "none";
                }
                if (entry.isIntersecting) observerButton.unobserve(entry.target);
            });
        });
        const observerWorkflow = new IntersectionObserver((entries) => {
            entries.forEach(
                (entry) => {
                    if (entry.intersectionRatio > 0) {
                        entry.target.style.opacity = "1";
                        if (entry.isIntersecting) observerWorkflow.unobserve(entry.target);
                    }
                },
                {
                    threshold: 0.5,
                }
            );
        });
        const observerLine = new IntersectionObserver((entries) => {
            entries.forEach(
                (entry) => {
                    if (entry.intersectionRatio > 0) {
                        entry.target.style.animation = `workflowAnimation 1.9s  ${entry.target.dataset.delay} forwards`;
                        if (entry.isIntersecting) observerLine.unobserve(entry.target);
                    }
                },
                
            );
        });

        lineRight.forEach((line) => {
            observerLine.observe(line);
        });
        lineLeft.forEach((line) => {
            observerLine.observe(line);
        });

        dots.forEach((dot) => {
            observerWorkflow.observe(dot);
        });

        buttons.forEach((button) => {
            observerButton.observe(button);
        });

        // const products = document.querySelectorAll(".home-products-grid-korban");
        // console.log(products);
        // const observerProducts = new IntersectionObserver((entries) => {
        //     entries.forEach(
        //         (entry) => {
        //             if (entry.intersectionRatio > 0) {
        //                 entry.target.style.animation = `productsAnimation 1.5s ${entry.target.dataset.delay} forwards`;
        //             } else {
        //                 entry.target.style.animation = "none";
        //             }
        //             if (entry.isIntersecting) observerProducts.unobserve(entry.target);
        //         },
        //         {
        //             rootMargin: "450px",
        //         }
        //     );
        // });
        // products.forEach((product) => {
        //     observerProducts.observe(product);
        // });
    },

    beforeUnmount() {
        window.removeEventListener("resize", this.onResize);
    },

    watch: {
        isVisible() {
            this.showScroll();
        },
    },

    methods: {
        onResize() {
            this.$store.dispatch("setscreenWidth", window.innerWidth);
        },

        hideLoader() {
            setTimeout(() => {
                this.isVisible = false;
            }, 2000);
        },

        showScroll() {
            if (this.isVisible === false) {
                document.querySelector("body").style.overflowY = "initial";
            }
        },

        setCookie(name, value, options) {
            this.cookiesStatus = false;

            const opts = {
                path: "/",
                ...options,
            };

            if (navigator.cookieEnabled) {
                const cookieName = encodeURIComponent(name);
                const cookieVal = encodeURIComponent(value);
                let cookieText = cookieName + "=" + cookieVal;

                if (opts.days && typeof opts.days === "number") {
                    const data = new Date();
                    data.setTime(data.getTime() + opts.days * 24 * 60 * 60 * 1000);
                    cookieText += "; expires=" + data.toUTCString();
                }

                if (opts.path) {
                    cookieText += "; path=" + opts.path;
                }
                if (opts.domain) {
                    cookieText += "; domain=" + opts.domain;
                }
                if (opts.secure) {
                    cookieText += "; secure";
                }

                document.cookie = cookieText;
            }
        },
        getCookie(name) {
            if (document.cookie !== "") {
                const cookies = document.cookie.split(/; */);

                for (let cookie of cookies) {
                    const [cookieName, cookieVal] = cookie.split("=");
                    if (cookieName === decodeURIComponent(name)) {
                        return decodeURIComponent(cookieVal);
                    }
                }
            }

            return undefined;
        },
        cookiePopup() {
            if (this.getCookie("cookie")) {
                this.cookiesStatus = false;
            } else {
                this.cookiesStatus = true;
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/scss/main", "~@/assets/scss/config", "~@/assets/scss/media-queries", "~@/assets/scss/default-styles";

@keyframes logoSlider {
    to {
        left: 0;
    }
}

@keyframes logoOpacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

#preloader-screen {
    background: white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 99999999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
}
.preloader-wrapper {
    position: relative;
    height: 150px;
    width: 950px;
    img {
        height: 100%;
        width: 100%;
        position: absolute;
    }
    #logo-e {
        left: -78px;
        animation: logoSlider 0.3s linear forwards;
        animation-delay: 300ms;
    }
    #trust {
        opacity: 0;
        animation: logoOpacity 0.7s linear forwards;
        animation-delay: 500ms;
    }
    #medical {
        opacity: 0;
        animation: logoOpacity 0.7s linear forwards;
        animation-delay: 700ms;
    }
    #dedykowane {
        opacity: 0;
        animation: logoOpacity 0.7s linear forwards;
        animation-delay: 900ms;
    }
    @include respond-below(xl) {
        height: 100px;
        width: 650px;
        #logo-e {
            left: -51px;
        }
    }
    @include respond-below(md) {
        height: 80px;
        width: 500px;
        #logo-e {
            left: -41px;
        }
    }
    @include respond-below(sm) {
        height: 50px;
        width: 300px;
        #logo-e {
            left: -24px;
        }
    }
}

.preloader-leave-from {
    opacity: 1;
}
.preloader-leave-active {
    transition: all 1s ease;
}
.preloader-leave-to {
    opacity: 0;
}
</style>
